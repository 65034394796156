export default {
  tooltip: {
    trigger: 'item',
    transitionDuration: 0,
    appendToBody: true
  },
  legend: {
    show: false
  },
  series: [
    {
      type: 'pie',
      radius: ['30%', '65%'],
      center: ['50%', '47%'],
      // roseType: 'radius',
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10
      },
      labelLine: {
        show: true,
        length: 15,
        length2: 5
      },
      label: {
        // show: true,
        // position: 'center',
        // color: '#000',
        // fontSize: 16,
        // fontWeight: 500,
        // lineHeight: 30
      },
      data: [
        {
          value: 300,
          name: '',
          itemStyle: {
            color: '#5470C6'
          }
        }
      ]
    }
  ]
}
